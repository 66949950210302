
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import { useStore } from "vuex";
import Entitytable from "@/components/EntityTable.vue";
import TableExplorer from "@/components/TableExplorer.vue";
import CloseClaimModal from "@/components/modals/CloseClaim.vue";
import ClaimFilters from "@/components/ClaimsFilters.vue";

export default defineComponent({
  name: "kt-widget-12",
  components: { ClaimFilters, CloseClaimModal, TableExplorer },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      filters: {},
      perPage: 10,
      entities: null,
      lastpage: 1,
      stringFilter: "",
      page: 1,
      pages: [],
      count: null,
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
});
