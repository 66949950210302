
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "CloseClaim-modal",
  components: { ElectronicSignature },
  emits: ["checkregistered"],
  props: {
    id: String,
    claimId: String,
  },
  computed: {
    canSendForm() {
      return false;
    },
  },
  methods: {
    triggerSignatureForm() {
      let modalito = new Modal(document.getElementById("sign-modal"));
      modalito.show();
    },

    catchErrors(error) {
      console.log(error);
      if (error.response.status == 451) {
        return this.triggerSignatureForm();
      }

      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },

    storeFormDataWithSignature(signature) {
      ApiService.post("claims", {
        reason_id: this.claimReason,
        status: 1,
        close_details: this.observations,
        electronicSignature: signature,
      })
        .then(() => {
          let myModal = document.getElementById("NewClaim-modal");
          if (myModal) {
            myModal.click();
          }
          this.$emit("checkregistered");
        })
        .catch(this.catchErrors);
    },
    closeClaim() {
      ApiService.put("claims/" + this.claimId, {
        status: 1,
        close_details: this.observations,
      })
        .then(() => {
          let myModal = document.getElementById(this.id as any);
          if (myModal) {
            myModal.click();
          }
          this.$emit("checkregistered");
        })
        .catch(this.catchErrors);
    },
  },
  mounted() {
    ApiService.get("claims/reasons").then((res) => {
      this.reasonList = res.data;
    });
  },
  setup() {
    const closeClaimModal = ref<null | HTMLElement>(null);

    return { closeClaimModal };
  },
  data: () => {
    return {
      reasonList: [],
      claimReason: "",
      observations: "",
      errors: {},
    };
  },
});
